import styles from "./NotesComponent.module.scss";
import React, { useEffect, useState } from "react";
import { UnitAccess, UnitAccessNotesResponse } from "@common/typing";
import { TextareaComponent } from "@common/react-components";
import { useToasts } from "react-toast-notifications";
import { AccessesService } from "@common/units-api";
import { authManager } from '@common/authentication';
import { Required, ThemeProvider } from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";

interface NotesProps { }

export const NotesComponent: React.FunctionComponent<NotesProps> = (props) => {
  const [unitAccessNotes, setUnitAccessNotes] = useState<UnitAccess>({
    type: "unit_access_units",
    attributes: {
      unit_id: null,
      additional_notes: null,
      internal_notes: null,
      unit_entrance_instructions: null
    }
  });

  const unitService = new AccessesService(authManager.getJwt());
  const info = authManager.getInfoFromAdmin<{ user: string; unitId: number; }>();
  const { addToast } = useToasts();

  useEffect(() => {
    (async () => {
      try {
        await loadUnitAccessNotes();
      } catch (e) {
        addToast(`Error loading Unit Access information`, { appearance: "error" });
      }
    })();
  }, []);


  const loadUnitAccessNotes = async () => {
    try {
      const unitAccessNotesResponse: UnitAccessNotesResponse = await unitService.getUnitAccessNotes(info.unitId);
      setUnitAccessNotes(unitAccessNotesResponse.data);
    } catch (e) {
      if (e.response.status !== 404) {
        addToast(`Error loading Access Notes information`, { appearance: "error" });
      }
    }
  }

  return (
    <div id={"notes-container"} className={`${styles.NotesContainer}`}>
      <div className={`${styles.NotesTextArea} ${styles.full}`}>
        <TextareaComponent
          selectedItem={unitAccessNotes}
          text={unitAccessNotes.attributes?.unit_entrance_instructions}
          id={`global_instructions`}
          name={"global_instructions"}
          placeholder={"Instruction summary"}
          maxLength={5000}
          readOnly={true}
          labelName={"Guest checkin email"}
          required={<ThemeProvider theme={_default}><Required type={"info"} size={"small"} text={"(Access information)"}></Required></ThemeProvider>}
          disabled={true}
          setItem={setUnitAccessNotes}
        />
      </div>
      {/* To be implemented in the future
      <div className={styles.LinkContainer}>
        <ThemeProvider theme={_default}>
          <b><a href={"#"} onClick={() => {}} className={`${styles.preview}`} ><Icon.Eye className={`${styles.eye}`} name="edit" width={16} height={16}/> Notification Preview</a></b>
        </ThemeProvider>
      </div>*/}
    </div>
  )
}