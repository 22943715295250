import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {Content} from "../components/principalView/Content";
import { AccessDetailsComponent } from "../components/modal/AccessDetailsComponent";

class RouterApp extends React.Component {
    render() {
      return (
        <Router>
          <Switch>
            <Route path="/details" component={AccessDetailsComponent} />
            <Route path="/" component={Content} />
          </Switch>
        </Router>
      );
    }
}

export default RouterApp;
