import React from "react";
import "./App.css";
import {ToastProvider} from "react-toast-notifications";
import RouterApp from "./router/Router";

function App() {
  return (
    <div className="App">
      <ToastProvider autoDismiss autoDismissTimeout={5000} placement="bottom-right">
        <RouterApp/>
      </ToastProvider>
    </div>
  );
}

export default App;